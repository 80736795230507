import React from 'react';

  function TimetableRow(props) {

    let dispTimes = (times) => {
      return times.map((time,ind) => {
        if (ind < 2) {
          return <td key={ind} className="busTime">{time.eta} <span>min</span></td>;
        }
      })
    }

    return (
      <tr className="timeTableRow">
        <td><span className="busNumber">{props.busData.number}</span></td>
        <td className="busName">{props.busData.name}</td>
        {dispTimes(props.busData.times)}
      </tr>
    )
  }

export default TimetableRow;

  


