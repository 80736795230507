import React, { Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import axios from "axios";
import moment from "moment";
import "moment/locale/sl";
import _ from "lodash";
import Schedule from "./Schedule.js";
import Showcase from "./Showcase.js";
import DefaultScreen from "./DefaultScreen.js";

class Main extends Component {
  constructor(props) {
    super(props);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const location = params.get("location")
      ? params.get("location")
      : "snezniska";

    this.updateDataTime = 15 * 60 * 1000; // 15 min
    this.updateScheduleTime = 5 * 60 * 1000; // in ms
    this.showScheduleTime = 5 * 60 * 1000; // in ms
    this.scheduleAPI = "https://www.ntf.uni-lj.si/urnik/api/";
    //this.scheduleAPI = 'http://localhost:8887/otgo-info-display-urniki/';
    this.showcaseAPI = `https://display-${location}.ntf.uni-lj.si/backend/wp-json/wp/v2/display/?acf_format=standard`;
    this.scheduleData = null;
    this.showcaseData = null;
    this.currentClassTolerance = 15; // in minutes
    this.featureClassTolerance = 3 * 60; // in minutes
    this.maxClasses = 10;
    this.state = {
      ind: -1,
      classes: null,
      showcase: null,
      showSchedule: true,
      slideChangeTime: 10 * 1000, // in ms
    };
  }

  componentDidMount() {
    // Get all data and set inital states
    this.getData();

    // Set interval for requesting data for API
    setInterval(() => this.getData(), this.updateDataTime);

    // Set interval for displaying different schedule data
    setInterval(() => {
      this.setState({
        classes: this.filterScheduleData(this.scheduleData),
      });
    }, this.updateScheduleTime);
  }

  showSchedule() {
    if (this.state.classes && this.state.classes.length !== 0) {
      console.log("Show schedule data.");
      this.setState({ ind: 0 });
      // Set timeout for schedule display
      setTimeout(() => this.showShowcase(), this.showScheduleTime);
    } else {
      this.setState({ ind: 1 });
    }
  }

  showShowcase() {
    if (this.state.showcase && this.state.showcase.length !== 0) {
      console.log("Show showcase");
      this.setState({ ind: 1 });
    }
  }

  getData() {
    axios
      .all([
        // Get schedule data
        axios.get(this.scheduleAPI, {
          params: {
            //date: '2019-03-01'
          },
        }),
        // Get showcase data
        axios.get(this.showcaseAPI),
      ])
      .then(
        axios.spread((scheduleResponse, showcaseResponse) => {
          // this.scheduleData = this.cleanCourses(_.toArray(scheduleResponse.data));

          this.showcaseData = this.prepareShowcaseData(
            _.toArray(showcaseResponse.data)
          );

          let newInd;
          let initInd = this.state.ind;
          let classes = this.filterScheduleData(this.scheduleData);
          let showcase =
            this.showcaseData.length !== 0 ? this.showcaseData : null;
          let showShedule = false;

          if (classes && showShedule) {
            newInd = 0;
          } else if (showcase) {
            newInd = 1;
          } else {
            newInd = -1;
          }

          if (initInd === -1 && newInd === 0) {
            setTimeout(() => this.showShowcase(), this.showScheduleTime);
          }

          this.setState({
            classes: classes,
            showcase: showcase,
            showSchedule: showShedule,
            ind: initInd === -1 ? newInd : initInd,
          });
        })
      );
  }

  prepareShowcaseData(data) {
    console.log(data);
    return data
      .filter(el => el.status === "publish")
      .map((element, index) => {
        let content;

        if (element.acf.pw_vrsta_vsebine === "gallery") {
          content = element.acf.pw_gallery.map(img => {
            return {
              src: img.sizes.screen_img,
              id: img.ID,
            };
          });
        } else if (element.acf.pw_vrsta_vsebine === "video") {
          content = element.videos.map(img => {
            return {
              duration: img.duration * 1000,
              html: img.html,
              id: img.video_id,
            };
          });
        } else if (element.acf.pw_vrsta_vsebine === "html") {
          content = element.acf.pw_custom_html.map((customContent, ind) => {
            return {
              html: customContent.pw_custom_html_content,
              id: `customContent-${ind}`,
            };
          });
        }

        return {
          id: `content-${element.id}`,
          title: element.acf.pw_naslovna_prosojnica
            ? element.acf.pw_naslovna_prosojnica_content
            : false,
          type: element.acf.pw_vrsta_vsebine,
          content: content,
        };
      });
  }

  filterScheduleData(data) {
    let now = moment();
    //console.log(now);
    let classes = [];

    if (data) {
      // loop trough courses
      for (let i = 0; i < data.length; i++) {
        let startTime = moment({
          hour: data[i].Start_Hour.split(".")[0],
          minute: data[i].Start_Hour.split(".")[1],
        });
        let endTime = moment({
          hour: data[i].End_Hour.split(".")[0],
          minute: data[i].End_Hour.split(".")[1],
        });

        if (endTime - now > 0) {
          // Predavanja se še niso začela oz. so v teku

          if (startTime - now < 0) {
            // predavanja so v teku
            if (
              startTime - moment().subtract(this.currentClassTolerance, "m") >
              0
            ) {
              // predavanja so se ravno začela, zato naj bodo prikazana
              classes.push(data[i]);
              //console.log(data[i].Course_Name, ':', startTime.format('HH.mm'), '-', endTime.format('HH.mm') )
            }
          } else {
            //classes.push(data[i]);
            // prihajajoča predavanja
            if (moment().add(this.featureClassTolerance, "m") - startTime > 0) {
              classes.push(data[i]);
            }
          }
        }
      } // end loop

      // Sort classes based on start hour
      classes = classes
        .sort((a, b) => {
          a = moment({
            hour: a.Start_Hour.split(".")[0],
            minute: a.Start_Hour.split(".")[1],
          });
          b = moment({
            hour: b.Start_Hour.split(".")[0],
            minute: b.Start_Hour.split(".")[1],
          });
          return a - b;
        })
        .filter((o, i) => i <= this.maxClasses - 1);

      return classes.length !== 0 ? classes : null;
    }
  }

  cleanCourses(data) {
    let cleanSet = [];
    let courses = _.clone(data);

    // Check for duplicate
    _.forEach(courses, function (o, i) {
      let eq = _.find(courses, function (e, ind) {
        if (i > ind) {
          // Preveri ce so katera predavanja v isti učilnici ob istem času
          return (
            e.End_Hour === o.End_Hour &&
            e.Start_Hour === o.Start_Hour &&
            e.Room_Name === o.Room_Name
          );
        }
      });
      if (eq) {
        let idx = _.findIndex(cleanSet, eq);
        //cleanSet[ idx ].program_name = cleanSet[ idx ].program_name + ', ' + o.program_name;
        //cleanSet[ idx ].program_year = cleanSet[ idx ].program_year + ', ' + o.program_year;
        cleanSet[idx].program_name_year =
          cleanSet[idx].program_name_year +
          ", " +
          o.program_name +
          " " +
          o.program_year;
      } else {
        o.program_name_year = o.program_name + " " + o.program_year;
        cleanSet.push(o);
      }
    });

    return cleanSet;
  }

  renderMainPart() {
    if (this.state.ind === -1) {
      return <DefaultScreen />;
    } else if (this.state.ind === 0 && this.state.classes) {
      return <Schedule courses={this.state.classes} />;
    } else if (this.state.ind === 1 && this.state.showcase) {
      return (
        <Showcase
          loop={!this.state.classes || !this.state.showSchedule}
          finishShowcase={() => {
            this.showSchedule();
          }}
          data={this.state.showcase}
        />
      );
    }
  }

  render() {
    return (
      <main>
        <TransitionGroup className="container">
          <CSSTransition
            key={this.state.ind}
            timeout={1000}
            classNames="fade-delay"
          >
            <div className="container">{this.renderMainPart()}</div>
          </CSSTransition>
        </TransitionGroup>
      </main>
    );
  }
}

export default Main;
