import React, {Component} from 'react';
import moment from 'moment';
import 'moment/locale/sl';

class Clock extends Component {
  constructor(props){
    super(props);
    moment.updateLocale('si');
    this.state = {
      time: this.getTime(),
      date: this.getDate()
    }
  }

  componentDidMount() {
    this.intId = setInterval(
      () => this.setState({
        time: this.getTime(),
        date: this.getDate()
      }),
      1000
    );
  }
  componentWillUnmount() {
    clearInterval(this.intId);
  }

  getTime(){
    return moment().format('HH.mm') 
  }

  getDate(){
    return moment().format('ddd Do MMMM')
  }

  render (){
    return(
      <section className="clock">
        <p>
          <time className="time" dateTime={this.state.time}>{this.state.time}</time>
        </p>
        <p>
          <time className="date" dateTime={this.state.date}>{this.state.date}</time>
        </p>
      </section>
    )
  }
  
}

export default Clock;
