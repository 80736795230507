import React from "react";
import logo from "../img/UL_NTF.svg";

function DefaultScreen(props) {
  return (
    <section className="defaultScreen">
      <div className="defaultLogo">
        <img src={logo} />
      </div>
    </section>
  );
}

export default DefaultScreen;
