import React, { Component } from 'react';
import axios from 'axios';

class Becikelj extends Component {
  constructor(props){
    super(props);
    this.state = {
      mdb: 0,
      nuk: 0
    };
  }

  componentDidMount() {
    this.intId = setInterval(this.getBikeInfo.bind(this), 1000*5);
  }

  getBikeInfo(){
    var self = this;
    axios.get('https://opendata.si/promet/bicikelj/list/', {
      headers: {
        // "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json'
      }
    })
    .then(function(response){
      self.setState({
        mbd: response.data.markers[13].station.available,
        nuk: response.data.markers[14].station.available
      });
      //console.log('Bikes Updated.')
    })
    .catch(function (error) {
      console.log(error);
    })
  }


  render() {
    return (
      <section className="becikelj">
        <div className="becikeljIcon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.632 120.131"><defs></defs><g id="Group_1" data-name="Group 1" transform="translate(-945.804 -39.72)"><path id="Path_1" data-name="Path 1" className="cls-1" d="M992.2 131.05V92.158a2.458 2.458 0 1 0-4.916 0v32.174h-.009a.263.263 0 0 1 0 .12c0 .018.009.037.009.055v4.749h.046v31.934a2.458 2.458 0 1 0 4.916 0v-29.974a.319.319 0 0 1-.046-.166z" transform="translate(-3.151 -3.798)"/><path id="Path_2" data-name="Path 2" className="cls-1" d="M1025.571 45.32l-11.846-2.781a2.457 2.457 0 0 0-.933-.028l-16.448 2.606a11.42 11.42 0 0 0-19.4 0l-16.448-2.606a2.426 2.426 0 0 0-.933.028l-11.85 2.781a2.456 2.456 0 0 0 .545 4.851 2.359 2.359 0 0 0 .564-.074l11.378-2.661 15.265 2.412a8.582 8.582 0 0 0-.028.859 10.933 10.933 0 0 0 8.763 10.755v12.89h-3.474a2.45 2.45 0 0 0-1.035.222l-5.932 2.781a2.493 2.493 0 0 0-1.432 2.245v46.727h-12.232a2.458 2.458 0 1 0 0 4.916h12.234v3.077a2.458 2.458 0 1 0 4.916 0v-6.44a.307.307 0 0 1-.037-.139v-7.207h-.018v-39.39l4.01-1.867h10.756l3.992 1.867v39.012a.306.306 0 0 1 .028.139v5.174h.018v8.861a2.458 2.458 0 1 0 4.916 0v-32.174h12.234a2.458 2.458 0 0 0 0-4.916h-12.234V79.591a2.476 2.476 0 0 0-1.432-2.245l-5.932-2.781a2.543 2.543 0 0 0-1.035-.222h-3.493V61.471a10.933 10.933 0 0 0 8.769-10.756c0-.3-.028-.564-.028-.859l15.265-2.412 11.375 2.661a2.36 2.36 0 0 0 .564.074 2.457 2.457 0 0 0 2.393-1.894 2.388 2.388 0 0 0-1.785-2.965zM986.6 56.8a6.084 6.084 0 1 1 6.3-6.08 6.052 6.052 0 0 1-6.3 6.08z"/></g></svg>
        </div>
        <div>
          <p>{this.state.mbd}<span>MDB</span></p>
        </div>
        <div>
          <p>{this.state.nuk}<span>NUK</span></p>
        </div>
      </section>
    );
  }
}

export default Becikelj;
