import React from 'react';
import ScheduleCourse from './ScheduleCourse.js';

function Schedule (props) {

  let renderClasses = function(courses){
    if (courses && courses.length !== 0){
      return courses.map( (element, index) => <ScheduleCourse key={index} course={element} /> );
    }
  }

  return (
    <section className="schedule">
      {/*}<h2>Predavanja in vaje:</h2>{*/}
      <ul>
        {renderClasses(props.courses)}
      </ul>
    </section>
  );
}

export default Schedule;
