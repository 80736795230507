import React from 'react';
import _ from 'lodash';

function ScheduleCourse(props) {

  let borderColor;

  if (props.course.program_name === 'NTO' || props.course.program_name === 'NTOM' || props.course.program_name === 'TOI'){
    borderColor = '#6BA539'; // GREEN
  } else if (props.course.program_name === 'OTO' || props.course.program_name === 'OTOM'){
    borderColor = '#A20067'; // PURPLE
  } else {
    borderColor = '#006BA6'; // BLUE
  }

  let style = {
    borderColor: borderColor
  };

  let removeDublicate = (courses) => {
    return _.uniq(courses.split(', ')).join(', ')
  }

  return (
    <li style={style} className="scheduleClass">
      <h3>{props.course.Start_Hour} | {props.course.Course_Name}</h3>
      <p>{props.course.Room_Name} | {removeDublicate(props.course.program_name_year)}</p>
    </li>
  )
}

export default ScheduleCourse;

  


