import React, { Component } from 'react';
import axios from 'axios';
import TimetableRow from './TimetableRow.js'

class Trola extends Component {

  constructor(props){
    super(props);

    this.changeTime = 15*1000; // in ms
    this.trolaURL = 'https://display.ntf.uni-lj.si/api/trola/';
    this.stations = [
      {
        name: 'Aškerčeva',
        number: '602051',
      },
      {
        name: 'Aškerčeva',
        number: '602052',
      },
      {
        name: 'Križanke',
        number: '602061',
      },
      {
        name: 'Križanke',
        number: '602062',
      }
    ];

    this.state = {
      stations: {
        '602051': null,
        '602052': null,
        '602061': null,
        '602062': null
      },
      indStat : 0,
      indBus : 0,
    };
  }

  init(){
    this.getStationsData();
  }

  componentDidMount() {
    this.init();
    
    setInterval(()=>{
      this.changeBusData()
    }, this.changeTime)
  }


  changeBusData(){
    //console.log('changing Bus data ...')
    var indStat = this.state.indStat;
    var indBus = this.state.indBus;
    let busNumber = this.stations[indStat].number;
    let stationBuses = this.state.stations[busNumber];

    if (stationBuses) {
      if ((indBus + 2) < stationBuses.length) {
        indBus += 2;
      } else {
        this.getStationsData();
        indBus = 0;
        indStat = (indStat+1) % this.stations.length;
      }

      this.setState({
        indStat : indStat,
        indBus : indBus,
      });
    }
  }
  

  getStationsData(){
    this.stations.forEach( (element, index) => {
      this.getStationData(element.number)
    });
  }

  /**
   * 
   * @param {array} arrivals 
   * arrivals for certain station
   */
  collectBusArrivals(arrivals){
    let arrivalsForEachBus = {}

    arrivals.forEach( (arrival, index) => {
      if ( !arrivalsForEachBus.hasOwnProperty(arrival.route_name) ) {
        arrivalsForEachBus[arrival.route_name] = [];
      }
      arrivalsForEachBus[arrival.route_name].push({
        type: arrival.type,
        eta: arrival.eta_min,
        name: arrival.trip_name,
        number: arrival.route_name
      });
    });

    let collectionOfArrivals = [];
    Object.values(arrivalsForEachBus).forEach(data =>{
      collectionOfArrivals.push({
        name: data[0].name,
        number: data[0].number,
        times: data.map(element => {
          return {
            eta: element.eta, 
            type: element.type
          }
        })
      });
    });
    return collectionOfArrivals;

  }

  getStationData(number){
    var self = this;
    console.log(number);

    axios.get(this.trolaURL, {
      params: {
        station_code: number      
      }
    })
    .then(function(response){
      console.log(response.data);
      let stations = self.state.stations;
      stations[number] = self.collectBusArrivals(response.data.data.arrivals);
        
      self.setState({
        stations: stations
      });
    })
    .catch(function (error) {
      console.log(error);
    })

  }

  dispTimetable(stationBuses, busInd){
    if (stationBuses) {
      if (busInd < stationBuses.length ) {
        return (
          <table className="timetable">
            <tbody>
              <TimetableRow busData={stationBuses[busInd]} />
              {stationBuses[busInd + 1] && <TimetableRow busData={stationBuses[busInd + 1]} />}
            </tbody>
          </table>
        )
      } else {

      }
    }
  }


  render() {
    let stationNumber = this.stations[this.state.indStat].number;
    let stationBuses = this.state.stations[stationNumber];
    return (
      <section className="trola"> 
        <div className="busIcon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.087 106.741"><defs></defs><g id="Group_5" data-name="Group 5" transform="translate(-40.56 -14.29)"><path id="Path_5" data-name="Path 5" className="cls-1" d="M112.407 14.29H50.825A10.291 10.291 0 0 0 40.56 24.555v73.9a9.689 9.689 0 0 0 4.206 7.7 4.579 4.579 0 0 0-.107.517v7.182a7.139 7.139 0 0 0 7.182 7.182h2.05a7.139 7.139 0 0 0 7.182-7.182v-5.132h41.052v5.132a7.139 7.139 0 0 0 7.182 7.182h2.05a7.139 7.139 0 0 0 7.182-7.182v-7.182a10.258 10.258 0 0 0 4.108-8.215v-73.9a10.259 10.259 0 0 0-10.24-10.265zm-55.425 99.559a3.027 3.027 0 0 1-3.075 3.083h-2.05a3.022 3.022 0 0 1-3.075-3.083v-5.641a10.587 10.587 0 0 0 3.075.508h5.132v5.133zm57.482 0a3.027 3.027 0 0 1-3.075 3.083h-2.05a3.022 3.022 0 0 1-3.075-3.083v-5.132h6.157a13.041 13.041 0 0 0 2.05-.2v5.337zm4.1-15.389a6.176 6.176 0 0 1-6.157 6.157H51.85c-3.288 0-7.182-2.771-7.182-6.157V79.979h73.9zm0-22.58h-73.9V34.82h73.9zm0-45.168h-73.9v-6.157a6.176 6.176 0 0 1 6.161-6.155h61.582a6.176 6.176 0 0 1 6.157 6.157zm0 0"/><path id="Path_6" data-name="Path 6" className="cls-1" d="M82.249 24.31H68.91a2.05 2.05 0 1 0 0 4.1h13.34a2.05 2.05 0 0 0 0-4.1zm0 0" transform="translate(-4.737 -1.805)"/><path id="Path_7" data-name="Path 7" className="cls-1" d="M99.524 24.31H96.45a2.05 2.05 0 0 0 0 4.1h3.083a2.05 2.05 0 1 0-.008-4.1zm0 0" transform="translate(-9.697 -1.805)"/><path id="Path_8" data-name="Path 8" className="cls-1" d="M57.987 112.995a6.157 6.157 0 1 0-6.157-6.157 6.176 6.176 0 0 0 6.157 6.157zm0-8.215" transform="translate(-2.03 -15.56)"/><path id="Path_9" data-name="Path 9" className="cls-1" d="M120.577 112.995a6.157 6.157 0 1 0-6.157-6.157 6.176 6.176 0 0 0 6.157 6.157zm0-8.215" transform="translate(-13.303 -15.56)"/></g></svg>
          <h2>{this.stations[this.state.indStat].name}</h2>
          <p>{this.stations[this.state.indStat].number}</p>
        </div>
        
        {this.dispTimetable(stationBuses, this.state.indBus)} 
        

      </section>
    );
  }
}

export default Trola;


