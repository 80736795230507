import React, {Component} from 'react';
import Clock from './Clock.js';
import Becikelj from './Becikelj.js';
import Trola from './Trola.js';
import Line from './Line.js';

class Footer extends Component {
  render (){
    return(
      <footer>
        <Trola />
        {/* <Line /> */}
        {/* <Becikelj /> */}
        {/* <Line /> */}
        <Clock />
      </footer>
    )
  }
  
}

export default Footer;
