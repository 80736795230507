import React, {Component} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import Content from './Content.js';


class Showcase extends Component {
  constructor(props){
    super(props);
    this.set = 0;
    this.content = 0;
    this.defaultInterval = 12 * 1000; // ms
    this.state = {
      setId: props.data[this.set].id, 
      type: props.data[this.set].type,
      content: props.data[this.set].content[this.content],
      contentId: props.data[this.set].content[this.content].id,
      title: (props.data[this.set].title) ? props.data[this.set].title : false,
    };

  }

  componentDidMount() {
    // Set interval
    // if title is set or the first item is the image set default timeout, otherwise set timeout based on video duration
    if ( this.state.title || this.state.type === 'gallery' || this.state.type === 'html' ){
      setTimeout(()=>this.change(), this.defaultInterval)
    } else {
      setTimeout(()=>this.change(), this.props.data[this.set].content[this.content].duration)
    }
    
  }

  change(){
    let timeInt;
    if (this.state.title) {
      // first iteration hide title if it set
      this.setState({title: false});
      timeInt = this.defaultInterval;
    } else {
      // next iterations change image or set new content set
      this.content += 1;

      if (this.content >= this.props.data[this.set].content.length) {
        this.set = this.props.loop ? (this.set + 1) % this.props.data.length : this.set + 1;
        this.content = 0;
      }

      if(this.set < this.props.data.length) {
        // define time interval
        timeInt = ( this.props.data[this.set].type === 'video') ?  this.props.data[this.set].content[this.content].duration : this.defaultInterval;

        // Set new state
        this.setState({
          setId: this.props.data[this.set].id,
          type: this.props.data[this.set].type,
          content: this.props.data[this.set].content[this.content],
          contentId: this.props.data[this.set].content[this.content].id,
          title: (this.props.data[this.set].title && this.content === 0) ? this.props.data[this.set].title : false,
        })

      } else {
        this.props.finishShowcase();
        return
      }
      
    }

    // Set time interval
    setTimeout(()=>this.change(), timeInt)
  }
  

  render (){
    return(
     <section className="showcase">
      <TransitionGroup className="content-container">
            <CSSTransition
                key={this.state.setId}
                timeout={1000}
                classNames="fade-delay"
            >
                <Content title={this.state.title} content={this.state.content} type={this.state.type} id={this.state.title ? `title${this.set}` : this.state.contentId} />
            </CSSTransition>
        </TransitionGroup>
     </section>
    )
  }
  
}

export default Showcase;
