import React, {Component} from 'react';
import Footer from './component/Footer';
import Main from './component/Main';

/*
 * Main Component for App
 */
class App extends Component {

  /* Render */
  render() {
    return (
        <div className="app">
          <Main />
          <Footer />
        </div>
    );
  }
}

export default App;

