import React from 'react';

  function Line(props) {

    return (
      <div className="line"></div>
    )
  }

export default Line;

  


