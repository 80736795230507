import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import renderHTML from "react-render-html";

const Content = ({ id, content, title, type }) => {
  const iframeRef = React.createRef();

  let render = (type, content) => {
    if (type === "gallery") {
      return <img src={content.src} alt="" />;
    } else if (type === "video") {
      return (
        <iframe
          ref={iframeRef}
          src={`https://player.vimeo.com/video/${content.id}?muted=1&autoplay=1&app_id=122963`}
          width="1920"
          frameBorder="0"
          onLoad={() => {}}
          title={content.id}
        ></iframe>
      );
    } else if (type === "html") {
      return (
        <article className="customHtml">{renderHTML(content.html)}</article>
      );
    }
  };

  return (
    <TransitionGroup className="image-container">
      <CSSTransition key={id} timeout={1000} classNames="fade-delay">
        {title ? (
          <article className="introSlide">
            <div>{renderHTML(title)}</div>
          </article>
        ) : (
          render(type, content)
        )}
      </CSSTransition>
    </TransitionGroup>
  );
};
export default Content;
